import React from 'react';
import Dialog from '@material-ui/core/Dialog';
// import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
// import closeIconCircle from '../../images/close-icon-circle.png'
import DashboardTranslations from '../../DashboardTranslations.js';
import closeIcon from '../../images/close-icon.png'
import '../styles/errorModal.scss'
import {getErrorMessage} from "../../messages/errors";


const  ErrorModal = (props) => (
    props.newModal ? 
    <div className='new-modal-box' style={{display: props.showError ? 'flex' : 'none'}}>
      <div className='new-modal-container'>
        <svg className='new-modal-icon' width="72" height="71" viewBox="0 0 72 71" fill="none">
          <g clip-path="url(#clip0_1388_544)">
            <path d="M35.7109 0.870361C16.1061 0.870361 0.210938 16.5416 0.210938 35.8704C0.210938 55.1991 16.1061 70.8704 35.7109 70.8704C55.3158 70.8704 71.2109 55.1991 71.2109 35.8704C71.2109 16.5416 55.3158 0.870361 35.7109 0.870361ZM35.7109 66.4954C18.5822 66.4954 4.64844 52.7579 4.64844 35.8704C4.64844 18.9829 18.5822 5.24536 35.7109 5.24536C52.8397 5.24536 66.7734 18.9829 66.7734 35.8704C66.7734 52.7579 52.8397 66.4954 35.7109 66.4954ZM35.7109 13.9954C34.534 13.9954 33.4053 14.4563 32.5732 15.2768C31.741 16.0972 31.2734 17.21 31.2734 18.3704V40.2454C31.2734 41.4057 31.741 42.5185 32.5732 43.339C33.4053 44.1594 34.534 44.6204 35.7109 44.6204C36.8878 44.6204 38.0165 44.1594 38.8487 43.339C39.6809 42.5185 40.1484 41.4057 40.1484 40.2454V18.3704C40.1484 17.21 39.6809 16.0972 38.8487 15.2768C38.0165 14.4563 36.8878 13.9954 35.7109 13.9954ZM31.2734 53.3004C31.2734 54.4607 31.741 55.5735 32.5732 56.394C33.4053 57.2144 34.534 57.6754 35.7109 57.6754C36.8878 57.6754 38.0165 57.2144 38.8487 56.394C39.6809 55.5735 40.1484 54.4607 40.1484 53.3004C40.1484 52.7258 40.0337 52.1569 39.8107 51.6261C39.5876 51.0953 39.2608 50.613 38.8487 50.2068C38.4367 49.8005 37.9475 49.4783 37.4091 49.2584C36.8707 49.0385 36.2937 48.9254 35.7109 48.9254C35.1282 48.9254 34.5512 49.0385 34.0128 49.2584C33.4744 49.4783 32.9852 49.8005 32.5732 50.2068C32.1611 50.613 31.8342 51.0953 31.6112 51.6261C31.3882 52.1569 31.2734 52.7258 31.2734 53.3004Z" fill="#FF6565"/>
          </g>
          <defs>
            <clipPath id="clip0_1388_544">
              <rect width="71" height="70" fill="white" transform="translate(0.210938 0.870361)"/>
            </clipPath>
          </defs>
        </svg>
        <div className='new-modal-title'>
          {props.errorTitle}
        </div>
        <div className='new-modal-subtitle'>
          {props.error}
        </div>
        <div className='new-modal-cta' onClick={() => props.handleCloseRedirect ? props.handleCloseRedirect() : props.handleClose()}>
          OK
        </div>
      </div>
    </div> :
    <div>
      <Dialog
        open={props.showError}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="error-modal"
        style={{}}
      >
        <DialogTitle className="dialog-title" id="alert-dialog-title"><img height="15" width="15" onClick={() => props.handleClose()} className="main-close-icon" src={closeIcon} alt="close-icon" /></DialogTitle>
        <div className="dialog-message-container">
          <div style={{padding: 15}}>
            <DialogContentText id="alert-dialog-description"  style={{fontSize: 14}}>
              <div className="close-icon-center" >
                <div className="error-modal-header-text">{DashboardTranslations.error_modal_header_text}</div>
              </div>
              {props.error ? props.error.split('\n').map ((item, i) => <div key={i}>{item}</div>) : getErrorMessage('generic')}
              <div className="ok-button" onClick={() => props.handleCloseRedirect ? props.handleCloseRedirect() : props.handleClose()}>OK</div>
            </DialogContentText>
          </div>
        </div>
      </Dialog>
    </div>
);

export default ErrorModal;
